.checkbox-label {
  color: #333;
  font-size: 11px;
  line-height: 20px;
  cursor: pointer;
}

.checkbox-input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-appearance: none;

  &:not(:disabled):hover {
    border: 1px solid #ededed;
  }

  &:focus {
    border: 1px solid #ddd;
  }

  &:checked {
    background-image: url(../../images/Project/checked.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  &:disabled {
    background-color: white;
    border: 1px solid #ededed;
  }
}
