.login-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.login-page-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 268px;
  height: 332px;
  padding: 32px 24px;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-page-logo-wrapper {
  display: inline-block;
  margin-bottom: 24px;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #e9eaee;
}

.logo-img-wrapper {
  display: none;
  float: left;
}

.login-page-input-container.password {
  margin-top: 12px;
}

.login-page-button-wrapper {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.login-page-button {
  width: 100%;
}

.logo-text-wrapper {
  display: inline-block;
  width: 188px;
  height: 32px;
  //padding-left: 12px;
  color: #006cb1;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: left;
}

.login-page__error-message {
  margin-top: 6px;
  color: #ff3131;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
