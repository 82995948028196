.results__single-chart {
  display: flex;
  flex-flow: column;
  padding-top: 24px;

  button {
    margin: 8px;
    padding: 4px 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  button.is-clicked {
    background: #f5f5f5;
    border-radius: 4px;
  }
}

.results__single-chart-dropdown {
  //margin-bottom: 30px;
  position: absolute;
  top: -6px;
  align-self: flex-end;
  width: 200px;
}
