.dialog-window-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.16);
}

.dialog-window-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 480px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  transform: translate(-50%, -50%);
}

.dialog-window-wrapper {
  position: relative;
  padding: 24px;
}

.dialog-window-title {
  height: 20px;
  color: #333;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  &.error {
    color: #ff3131;
  }
}

.dialog-window-message {
  width: 100%;
  min-height: 16px;
  margin-top: 14px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;

  &.message-details {
    margin-top: 9px;
  }

  & .object-name {
    padding: 4px;
    font-weight: 600;
    background-color: #f5f5f5;
  }

  &.cancel-task-message {
    margin-top: 18px;
  }
}

.dialog-window-close-button {
  position: absolute;
  top: 26px;
  right: 26px;
  width: 16px;
  height: 16px;

  &:not(:hover) svg path {
    fill: #aaa;
  }
}

.dialog-window-buttons-wrapper {
  display: flex;
  height: 32px;
  margin-top: 19px;
}

.dialog-window-delete-button {
  margin-right: 8px;
}

.dialog-window__create-button {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 8px;
}

.dialog-window__cancel-button {
  display: flex;
  align-items: center;
}

.dialog-window-options {
  margin: 18px 0;
}

.dialog-window__with-icon-wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}

.dialog-window__loader {
  width: 40px;
  height: 40px;
  margin-top: 34px;
  margin-bottom: 12px;
}

.dialog-window__loader-text {
  margin-bottom: 18px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
}
