.zoom_buttons_container {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  .zoom_buttons_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 8px -2px rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 8px -2px rgba(34, 60, 80, 0.2);

    .zoomIn_button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 10px 10px 10px;
    }

    .zoom_bottons_divider {
      width: 15px;
      margin: 10px 0;
      border: 1px solid #eee;
    }

    .zoomOut_button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px 20px 10px;
    }
  }

  .pan_button_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 8px -2px rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 8px -2px rgba(34, 60, 80, 0.2);

    .pan_button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px;
    }
  }
}
