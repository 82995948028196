.input {
  width: 100%;
  height: 32px;
  padding: 6px 8px;
  color: #333;
  font-size: 11px;
  line-height: 20px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;

  &:not(:disabled,.error):hover {
    border: 1px solid #ededed;
  }

  &.error {
    border: 1px solid #ff3131;
  }

  &:not(.error):focus {
    border: 1px solid #ddd;
  }

  &:disabled {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
  }

  &.input-with-prefix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-label {
  display: block;
  margin-bottom: 2px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
}
