
@font-face {
    font-family: 'Inter';
    font-weight: normal;
    src: url(webfonts/Inter/static/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 100;
    src: url(webfonts/Inter/static/Inter-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 200;
    src: url(webfonts/Inter/static/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 300;
    src: url(webfonts/Inter/static/Inter-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url(webfonts/Inter/static/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url(webfonts/Inter/static/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url(webfonts/Inter/static/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: bold;
    src: url(webfonts/Inter/static/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url(webfonts/Inter/static/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: url(webfonts/Inter/static/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: url(webfonts/Inter/static/Inter-Black.ttf) format('truetype');
}

