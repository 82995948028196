.radio-input-row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.radio-input {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  border-radius: 14px;
  -webkit-appearance: none;

  &:not(:disabled):hover {
    border: 1px solid #ededed;
  }

  &:disabled {
    background-color: white;
  }
}

.radio-input:checked::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #00aeef;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: '';
}

.radio-label {
  color: #333;
  font-weight: normal;
  font-size: 11px;
  font-style: normal;
  line-height: 16px;
}

.radio-label-icon {
  margin-top: 2px;

  &.disabled {
    path {
      fill: #aaa;
    }
  }
}
