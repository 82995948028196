.well-model-panel__tables-wrapper {
  position: relative;
  width: 100%;
  margin-top: 32px;
}

.well-model-panel__non-scrollable-table {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  border-right: 1px solid #ededed;
}

.well-model-panel__scrollable-table-wrapper {
  position: absolute;
  top: 20px;
  left: 91px;
  z-index: 5;
  width: calc(100% - 92px);
  overflow-x: auto;
}

.well-model-panel__scrollable-table-wrapper::-webkit-scrollbar {
  height: 12px;
}

.well-model-panel__scrollable-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 32px;
}

.well-model-panel__scrollable-table {
  min-width: 100% //376px;
}

.well-model-panel__table-cell {
  padding: 7px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  font-style: normal;
  line-height: 16px;
  text-align: right;
  border: 1px solid #ededed;
  cursor: default;

  &.value-cell {
    height: 32px;

    &.control-action-cell {
      width: 70px;
    }

    &.pressure-cell {
      min-width: 78.5px;

      &:last-child {
        border-right: 1px solid white;
      }

      &:first-child {
        border-left: 1px solid white;
      }
    }
  }
}

.well-model-panel__table-header-cell {
  padding: 7px 12px;
  color: #aaa;
  font-weight: 600;
  font-size: 11px;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  background: #f5f5f5;

  &.caption-cell {
    height: 20px;
    padding: 1px 0;
  }

  &.vertical-text {
    width: 20px;
    padding: 0 1px;
    transform: rotate(180deg);
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-lr;
  }

  &.right-aligned {
    text-align: right;
  }

  &.well-model-panel__table-cell.pressure-cell:last-child {
    border-right: 1px solid #f5f5f5;
  }

  &.well-model-panel__table-cell.pressure-cell:first-child {
    border-left: 1px solid #f5f5f5;
  }
}

.well-model-panel__table-bottom-cell {
  border-bottom: 1px solid #ededed;
}

.well-model-panel__table-bottom-header-cell {
  width: 20px;
  height: 32px;
  padding: 0;
  background: #f5f5f5;
  border: 1px solid #ededed;

  &:nth-child(2) {
    width: 70px;
  }
}

.editable-cell {
  position: relative;
  padding: 0;
  text-align: left;
  cursor: pointer;

  &:focus {
    & .editable-cell-icon {
      opacity: 0;
    }
  }

  & .editable-cell-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);

    & path {
      fill: #ededed;
    }
  }

  &:hover {
    & .editable-cell-icon path {
      fill: #333;
    }
  }
}

.no-model__message {
  margin-top: 20px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  font-style: normal;
  line-height: 14px;
}

.no-model__create-btn {
  margin-top: 12px;
}

.well-model-panel__buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
}

.well-model-panel__save-btn {
  margin-right: 8px;
}

.well-model-panel__parameter-input {
  width: 100%;
  padding: 7px 32px 7px 7px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    padding: 7px;

    & ~ .editable-cell-icon {
      opacity: 0;
    }
  }
}
