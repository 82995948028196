.skeleton-dashboard {
  width: 64px;
  height: 20px;

  &.short {
    width: 32px;
  }

  &.min-max {
    display: inline-block;
    width: 24px;
    height: 7px;
  }
}

.skeleton-table {
  float: right;
  width: 32px;
  height: 16px;

  &.long {
    width: 64px;
  }

  &.left {
    float: left;
  }
}

.skeleton-project-name {
  width: 256px;
  height: 16px;
}

.skeleton-project-date {
  width: 64px;
  height: 12px;
}

.skeleton-root {
  background: #f5f5f5;
  animation: skeleton-keyframes-pulse 0.75s ease-in infinite;
}

@keyframes skeleton-keyframes-pulse {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}
