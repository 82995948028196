.results-tables__container {
  position: relative;
}

.project-results-panel {
  width: 926px;
  height: calc(100vh - 129px);
  padding: 24px 0;
  overflow-y: auto;
  border-right: 1px solid #ededed;

  &.fullscreen {
    width: 100vw;

    & .dashboard.calc-info {
      grid-template-columns: repeat(5, 169px);
      width: 100vw;
      max-width: 100vw;
    }
  }
}

.project-results-panel__pipelines-gradient {
  position: absolute;
  bottom: 20px;
  left: 950px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  // min-width: 500px;
  width: 100%;
  max-width: 500px;
  height: 100px;
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
}

.project-results-panel__pipelines-gradient-scale-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  .project-results-panel__pipelines-gradient-scale {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 10px;
    border-radius: 2px;
    // background-color: #000;
  }
}

.project-results-panel__pipelines-gradient-values {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.charts__segments-scale_values {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 5px;

  & p {
    color: #aaa;
    font-size: 10px;
  }
}

.project-results-panel-header {
  display: flex;
  align-items: center;
}

.project-results-panel__buttons {
  position: absolute;
  right: 28px;
  display: flex;
  gap: 40px;
  align-items: center;
}

.project-results-panel__title-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 24px;

  &.initial {
    margin-bottom: 24px;
  }

  &.compare {
    margin-bottom: 20px;
  }
}

.project-results-panel__title {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.project-results-panel__export-btn {
  display: flex;
  align-items: center;
  width: 105px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &:disabled {
    cursor: default;

    &:not(.exporting) {
      color: #aaa;
    }

    & .project-results-panel__export-icon path {
      fill: #aaa;
    }
  }

  &:not(:disabled):hover {
    color: #29af62;
  }

  &:not(:disabled):active {
    color: #249d58;

    & .project-results-panel__export-icon path {
      fill: #249d58;
    }
  }
}

.project-results-panel__optimal-icon,
.project-results-panel__export-icon,
.project-results-panel__aspo-icon,
.project-results-panel__applied-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.project-results-panel__optimal-btn {
  display: flex;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &:disabled {
    color: #aaa;
    cursor: default;

    & .project-results-panel__optimal-icon path {
      fill: #aaa;
    }
  }
}

.project-results-panel__progress-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  animation: spin 4s linear infinite;

  &.export-progress-icon path {
    fill: #29af62;
  }
}

.project-results-panel__aspo-btn {
  display: flex;
  align-items: center;
  width: 118px;
  margin-right: 32px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &.af-btn {
    width: 154px;
    margin-right: 0;
  }

  &:disabled {
    cursor: default;

    &:not(.applyingAspo),
    &:not(.applyingAF) {
      color: #aaa;
    }

    & .project-results-panel__aspo-icon path {
      fill: #aaa;
    }
  }

  &:not(:disabled):hover {
    color: #00aeef;
  }

  &:not(:disabled):active {
    color: #009cd6;

    & .project-results-panel__aspo-icon path {
      fill: #009cd6;
    }
  }
}

.project-results-panel__message {
  margin-top: 24px;
  padding: 0 24px;
  font-weight: normal;
  font-size: 11px;
  font-style: normal;
  line-height: 14px;

  &.error {
    margin-top: 0;
  }
}

.project-results-panel__message-details {
  position: relative;
  width: 532px;
  height: 36px;
  margin: 20px 24px 0 24px;
  padding: 8px 40px 8px 12px;
  color: #333;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  text-align: left;
  background-color: #f5f5f5;
  border-radius: 4px 4px 4px 4px;

  &:hover {
    background-color: #f7f7f7;
  }

  &.extended {
    border-radius: 4px 4px 0 0;

    & .project-results-panel__arrow-icon {
      transform: rotate(180deg);
    }
  }
}

.project-results-panel__arrow-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
}

.project-results-panel__message-details-text {
  width: calc(100% - 48px);
  margin: 0 24px;
  padding: 8px 12px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  font-family: Consolas, sans-serif;
  line-height: 16px;
  white-space: pre-wrap;
  border: 1px solid #ededed;
  border-radius: 0 0 4px 4px;
}

.results__tabs {
  padding: 0 24px;
}

.dashboard {
  /* display: flex;
  flex-flow: nowrap;
  justify-content: space-between; */
  display: grid;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  padding: 12px 24px;
  background: #f7f7f7;
  border-radius: 2px;

  &.calc-info {
    position: absolute;
    bottom: 0;
    left: 0;
    //height: 73px;
    grid-template-columns: repeat(5, 169px);
    width: 99.8vw;
    max-width: 925px;
    margin-top: 20px;
    padding: 20px 16px;
    background: white;
    box-shadow: 4px 0 12px rgba(0, 0, 0, 0.04);
  }

  &.af-dashboard {
    grid-template-columns: repeat(1, 1fr);
  }
}

.dashboard__item {
  position: relative;
  //width: 207px;
  padding: 16.5px 12px;
  background: linear-gradient(0deg, #fff, #fff), #f3f4f8;
  border-radius: 2px;

  &.short {
    width: 80px;
  }

  &.af_item {
    width: 157px;
  }

  &.grow {
    flex-grow: 1;
  }

  &.error {
    //display: flex;
    //flex-direction: column;
    min-height: 110px;
    max-height: 120px;
    //margin-right: 17px;
    //padding: 22px 16px;
  }

  &.error:last-child {
    margin-right: 0;
  }

  &.optimal-item {
    padding: 8px 12px;
  }
}

.dashboard__item-dem {
  margin-bottom: 1vh;
  color: #aaa;
  font-weight: 600;
  font-size: 12px;
  //line-height: 16px;
}

.dashboard__item-value {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.dashboard__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.min-max-values {
  position: absolute;
  top: 32px;
  right: 20px;
  color: #999;
  font-weight: normal;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
  text-align: right;
}

.error-value {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: #999;
  font-weight: normal;
  font-size: 10px;
  font-style: normal;
  line-height: 10.89px;
  text-align: left;

  div:first-child {
    margin-right: 1px;
  }

  div > p > text {
    color: #333;
    font-weight: 600;
    font-size: 9px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div > p {
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  div > p:last-child {
    //padding-bottom: 22px;
  }
  //  div > div {
  //  flex-flow: row;
  //    align-self: flex-start;
  //}
}

.color-indication {
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 10px;
  line-height: 12.1px;

  p {
    margin-top: 4px;
    margin-right: 4px;
    //padding: 2px 6px;
    //border-radius: 4px;
  }

  &.red {
    color: #ff3131;

    p {
      //background-color: #ffe6e6;
    }
  }

  &.green {
    color: #33b96c;

    p {
      //background-color: #e5f5ec;
    }
  }

  &.gray {
    color: #aaa;
  }
}

.results__tab-list {
  display: -webkit-flex;
  display: flex;
  margin-top: 24px;

  &.underline {
    border-bottom: 1px solid #ededed;

    & .results__tab {
      padding-bottom: 12px;

      &.compare {
        color: #333;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
      }
    }

    &.none {
      border-bottom: 1px solid white;
    }
  }

  &.tables-tab-list {
    justify-content: space-between;
    margin: 12px 0;
  }
}

.results__tab {
  position: relative;
  margin-right: 16px;
  color: #aaa;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    color: #666;
  }

  &.react-tabs__tab--selected,
  &.active {
    color: #333;
  }
}

.results__icon {
  position: relative;
  margin-right: 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  & path {
    fill: #aaa;
  }

  &:not(.react-tabs__tab--disabled):hover path {
    fill: #666;
  }

  &.react-tabs__tab--selected path {
    fill: #333;
  }

  &.react-tabs__tab--disabled {
    cursor: default;
  }
}

.results__table-tabs {
  display: flex;
  align-items: center;
}

.results_tabs-step-row {
  position: relative;
  width: 128px;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.input.results_tabs-step-input {
  padding-right: 72px;
}

.results_tabs-step-input-unit {
  position: absolute;
  top: 6px;
  right: 8px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 20px;
}

.table__nodes-table,
.table__sources-table,
.table__segments-table {
  min-width: 100%;
}

.table-head__row {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f7f7f7;
  -webkit-transform: translate3d(0, 0, 0);

  &::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    background: #ededed;
    content: '';
  }
}

.table-row {
  &.looping {
    background: rgba(243, 189, 65, 0.08);
  }
}

.table-head__cell {
  padding: 10px;
  color: #aaa;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;
  text-align: left;
  background: #f7f7f7;
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;

  &:first-child {
    border-left: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &.right {
    text-align: right;
  }
}

.compare-field {
  &.value {
    color: #333;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  }

  &.compare {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 5px;
    color: #aaa;
    font-weight: 600;
    font-size: 8px;
    line-height: 9.68px;

    p {
      display: flex;
      flex-flow: nowrap;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;

      span:last-child {
        margin-left: 2px;
      }
      text:last-child {
        margin-left: 2px;
      }
    }

    p:last-child {
      margin-left: 3px;
    }
  }
}

.table-data {
  position: relative;
  padding: 10px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  border: 1px solid #e9eaee;

  &.width-150 {
    min-width: 150px;
  }

  &.width-112 {
    min-width: 112px;
  }

  &.green {
    background-color: #e5f5ec;
    .compare {
      color: #29af62;
    }

    text {
      color: #29af62;
    }
  }

  &.red {
    background-color: #ffe6e6;
    .compare {
      color: #ff3131;
    }
    text {
      color: #ff3131;
    }
  }

  &.left {
    white-space: nowrap;
    text-align: left;
  }

  &.compare {
    min-width: 64px;
    height: 44px;
  }

  &.big {
    min-width: 100px;
  }

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  &.table-status-img {
    text-indent: 14px;
  }

  &.table-status-img::before {
    position: absolute;
    top: 14px;
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
  }

  &.table-status-img.working::before {
    background-color: #2da45e;
  }

  &.table-status-img.stopped::before {
    background-color: #aaa;
  }
}

.table-status {
  width: 32px;
  height: 18px;
  padding-top: 5px;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  border-radius: 2px;

  &.working {
    color: #29af62;
    background-color: #e5f5ec;
  }

  &.stopped {
    color: #aaa;
    background-color: #f5f5f5;
  }
}

.results-tables__container .scrollarea {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border-top: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
}

.subscript {
  font-size: 8px;
  vertical-align: sub;
}
.sup {
  font-size: 8px;
  vertical-align: super;
}

.project-results-panel__compare-btn {
  width: 192px;
  height: 32px;
  color: #333;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  background-color: #f5f5f5;
  border-radius: 100px;

  &.compare {
    color: white;
    background-color: #815df6;
  }
}

.project-results-panel__full-size-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 32px;
  height: 32px;
  background-color: #f5f5f5;
  border-radius: 50%;
}

.calculation_info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  background-color: white;

  &.calc-info {
    //padding-left: 23px;
    color: #333;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

    p {
      color: #aaa;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
