.main-button {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;

  &.primary {
    color: #fff;
    background: #00aeef;

    &:not(:disabled):hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #00aeef;
    }

    &:not(:disabled):active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #00aeef;
    }
  }

  &.secondary {
    color: #333;
    background: #f5f5f5;

    &:not(:disabled):hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #f5f5f5;
    }

    &:not(:disabled):active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #f5f5f5;
    }
  }

  &:disabled {
    color: #aaa;
    background: #fff;
    border: 1px solid #ededed;
    cursor: default;
  }
}
