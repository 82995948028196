@import './InterFonts.css';
@import '~reset-css-complete/reset.css';

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

input {
  border: none;
  outline: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

div[role='button']:focus-visible {
  outline: none;
}

div:focus-visible,
canvas:focus-visible {
  outline: none;
}

html,
body {
  font-weight: normal;
  font-family: Inter, sans-serif;
}

.relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.margin-top {
  margin-top: 16px;
}

iframe {
  display: none;
}

.unit {
  position: relative;
}

.index {
  position: absolute;
  top: -6px;
  right: -5px;
  font-size: 10px;

  &.small {
    top: -4px;
    right: -4px;
    font-size: 8px;
  }

  &.unit-small {
    top: -6px;
    right: -6px;
    font-size: 8px;
  }
}

.small .index {
  top: -4px;
  right: -4px;
  font-size: 8px;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
  background: white;
  //border: 1px solid #ededed;
}

::-webkit-scrollbar-thumb {
  max-width: 30px;
  background-color: #ddd;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 60px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}
