.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  text-align: center;
  background-color: #fff;
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px dashed #00aeef;

    & .dropzone__text {
      color: #00aeef;
    }
  }

  &.active {
    background: rgba(0, 174, 239, 0.08);
    border: 1px solid #00aeef;
  }

  &.error {
    border: 1px dashed #ff3131;

    & .dropzone__text,
    .dropzone__text_bold {
      color: #ff3131;
    }
  }
}

.dropzone__text {
  color: #aaa;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;

  &_bold {
    color: #00aeef;
    font-weight: 600;
  }

  &.active {
    color: #00aeef;
    font-weight: 600;
  }
}
