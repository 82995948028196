.search-input-container {
  position: relative;
}

.search-input {
  width: 100%;
  height: 32px;
  padding: 0 8px;
  color: #333;
  font-weight: 500;
  font-size: 12px;
  font-family: inherit;
  line-height: 20px;
  background: #f5f5f5;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &::placeholder {
    color: #aaa;
    font-weight: 400;
    font-size: 11px;
    font-family: inherit;
    font-style: normal;
    line-height: 20px;
  }

  &:placeholder-shown {
    text-indent: 22px;
  }

  &:not(:placeholder-shown) {
    & ~ .search-input-search-icon {
      display: none;
    }

    &:focus {
      & ~ .search-input-clear-button {
        display: block;
      }
    }
  }

  &:focus {
    text-indent: 0;
    outline: none;

    & ~ .search-input-search-icon {
      display: none;
    }
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.search-input-clear-button {
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;

  & svg {
    opacity: 0.64;
  }

  &:hover svg {
    opacity: 1;
  }
}

.search-input-search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: block;
  width: 12px;
  height: 12px;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.64;
  }

  &:hover svg {
    opacity: 1;
  }
}
