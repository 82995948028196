.active-object__content {
  margin: 0 20px;

  &.full-width {
    width: calc(100% - 40px);
  }
}

.active-object__tabs {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
}

.active-object__tab-list {
  display: flex;
  height: 44px;
}

.active-object__panels {
  margin: 0 20px 20px 20px;
}

.active-object__tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  font-style: normal;
  line-height: 16px;
  cursor: pointer;

  &:first-child:not(.react-tabs__tab--selected) {
    background: #f5f5f5;
    border-radius: 0 8px 0 0;
  }

  &:nth-child(2):not(.react-tabs__tab--selected) {
    background: #f5f5f5;
    border-radius: 8px 0 0 0;
  }

  &.react-tabs__tab--disabled {
    color: #aaa;
    cursor: default;
  }
}
